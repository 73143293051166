import React from 'react';
import {
  faChartSimple,
  faFileSignature
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dAppName } from 'config';
import { logout } from 'helpers';
import { useGetIsLoggedIn } from 'hooks';
import { routeNames } from 'routes';
import { ReactComponent as MultiversXLogo } from '../../../assets/img/multiversx.svg';

export const Navbar = () => {
  const isLoggedIn = useGetIsLoggedIn();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  return (
    <BsNavbar
      className='navbar navbar-expand-lg navbar-dark'
      style={{ backgroundColor: 'black', color: 'white' }}
      aria-label='Ninth navbar example'
    >
      <div className='container-xl'>
        <Link className='navbar-brand' to={isLoggedIn ? routeNames.dashboard : routeNames.home}>
          <img src='srblogo.png' width={'56px'} alt='Logo'></img>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarsExample07XL'
          aria-controls='navbarsExample07XL'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarsExample07XL'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <Link className='nav-link' to='/'>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='https://www.quantumx.network/hypezone' target='_blank'>
                HypeZone
              </a>
            </li>
          </ul>

          <Nav>
          {isLoggedIn ? (
                <button
                  style={{ backgroundColor: '#9F4C4B', borderRadius: '30px', color: 'white' }}
                  className='btn btn-link'
                  onClick={handleLogout}
                >
                  Disconnect
                </button>
            ) : (
                <button
                  style={{ backgroundColor: '#22F6DC', borderRadius: '30px', color: 'black' }}
                  className='btn btn-link'
                  data-testid='loginBtn'
                  onClick={() => {
                    // Navigate to the routeNames.unlock route
                    window.location.href = routeNames.unlock;
                  }}
                >
                  Mint SRB Clown Santa
                </button>
            )}
          </Nav>
        </div>
      </div>
    </BsNavbar>
  );
};
