import * as React from 'react';
import { Link } from 'react-router-dom';
import { AuthRedirectWrapper } from 'components';
import { routeNames } from 'routes';

const HomePage = () => {
  return (
    <>
    <div className='row w-100 h-100 no-gutters'>
      <div className="col-sm" style={{ height: '100vh' }}>
        <div className="h-50 d-flex flex-column justify-content-center" style={{ fontSize: '45px', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(Multiversx.png)',backgroundSize:'35%',backgroundPosition:'right top',backgroundRepeat:'no-repeat'  }}>
          <span style={{width:'70%',fontSize:'35px',padding:'25px'}}>Own a piece<strong> of SRB Clown Santa</strong> with this exclusive NFT collection</span>
          <span style={{fontSize:'45px',marginLeft: '30px',marginBottom:'20px'}}><strong>316 SRB Clown Santa </strong></span>
        </div>
        <div className="h-50 d-flex flex-column justify-content-center" style={{ fontSize: '45px', backgroundColor: 'black',boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white' }}>
          <div><img src="hypey.png" width={'200px'} height={'auto'}></img>
          <Link style={{marginBottom:'60px'}}
          to={routeNames.unlock}
          className='btn btn-dark mt-3 text-white'
          data-testid='loginBtn'>
          Mint NFT
        </Link></div>
        </div>
      </div>
      <div className="col-sm align-items-center justify-content-between" style={{ height: '100vh' }}>
        <div className="d-flex flex-column justify-content-center align-items-center " style={{ fontSize: '25px',height:'65%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(goldhypey.png)',backgroundSize:'45%',backgroundPosition:'center center',backgroundRepeat:'no-repeat'}}>
          <span className='mt-auto' style={{fontSize:'25px',marginTop:'25px'}}><strong>Join the SRB Clown Santa</strong></span>
          <span style={{width:'50%',fontSize:'14px',marginTop:'15px',marginBottom:'25px',textAlign:'center'}}>A small collection of 316 fun loving NFTs!</span>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ fontSize: '45px',height:'35%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white' ,
      }}>
        <span style={{fontSize:'35px',padding:'20px',textAlign:'center'}}><strong> Special SRB Christmas collection.</strong> </span>
        <span style={{fontSize:'25px'}}>Are you as excited as we are? </span>
        </div>
      </div>
      <div className="col-sm" style={{ height: '100vh' }}>
        <div className="d-flex flex-column justify-content-center" style={{ fontSize: '45px',height:'65%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundSize:'55%',backgroundPosition:'left top',backgroundRepeat:'no-repeat' }}>
          <span className='mt-auto' style={{width:'65%',fontSize:'45px',padding:'25px'}}>Capture <strong> the essence</strong> of SRB Clown Santa collection</span>
        </div>
        <div className="d-flex flex-column justify-content-center" style={{ fontSize: '45px',height:'35%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundSize:'45%',backgroundPosition:'left left',backgroundRepeat:'no-repeat' }}>
          <span style={{textAlign:'right',marginRight:'20px'}}>Mint with</span>
          <div style={{textAlign:'end'}}>
            <span style={{textAlign:'right',marginRight:'20px'}}>just 1 $RARE</span>
          {/*<img style={{marginRight:'10px'}} src="hypelogo.png" width={'60px'} height={'auto'}></img>*/}
          <img style={{marginRight:'10px'}} src="rarelogo.png" width={'60px'} height={'auto'}></img>
          {/*<img style={{marginRight:'20px'}} src="Multiversx.png" width={'60px'} height={'auto'}></img>*/}
          </div>

        </div>
      </div>
    </div>
  </>

  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
